/**
 * @generated SignedSource<<23d794bf5ab47b585a5379a4ba02163c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type mainFavouriteQueue$data = {
  readonly items: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: string;
        readonly " $fragmentSpreads": FragmentRefs<"favouriteContentContent">;
      };
    }>;
  } | null | undefined;
  readonly " $fragmentType": "mainFavouriteQueue";
};
export type mainFavouriteQueue$key = {
  readonly " $data"?: mainFavouriteQueue$data;
  readonly " $fragmentSpreads": FragmentRefs<"mainFavouriteQueue">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "mainFavouriteQueue",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 6
        }
      ],
      "concreteType": "QueueItemConnection",
      "kind": "LinkedField",
      "name": "items",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QueueItemsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "favouriteContentContent"
                    }
                  ],
                  "type": "Content",
                  "abstractKey": "__isContent"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "items(first:6)"
    }
  ],
  "type": "Queue",
  "abstractKey": null
};

(node as any).hash = "dc164351c7643d7031babc8d63f8f394";

export default node;
