import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { tracking } from "shared/data";

import { page as SearchPage } from "scmp-app/pages/search";
import type { mainFavouriteQueue$key } from "scmp-app/queries/__generated__/mainFavouriteQueue.graphql";

import { FavouriteContent } from "./favourite-content";
import {
  DescriptionContainer,
  FavouriteContainer,
  FavouriteContentContainer,
  FavouriteTitle,
  MainContainer,
  SearchContainer,
  StyledBaseLink,
  TitleAndDescriptionContainer,
  TitleContainer,
} from "./styles";

export type Props = {
  reference: mainFavouriteQueue$key;
};

export const Main: FunctionComponent<Props> = ({ reference: reference_ }) => {
  const { items } = useFragment(
    graphql`
      fragment mainFavouriteQueue on Queue {
        items(first: 6) {
          edges {
            node {
              __typename
              ... on Content {
                ...favouriteContentContent
              }
            }
          }
        }
      }
    `,
    reference_,
  );

  const { pathname, query } = SearchPage.route({
    module: tracking.module.SCMPFavouritesLink,
    pgtype: tracking.pageType.NotFound,
  });

  return (
    <MainContainer>
      <TitleAndDescriptionContainer>
        <TitleContainer>404</TitleContainer>
        <DescriptionContainer>
          The page you were looking for appears to have moved or never existed.
        </DescriptionContainer>
        <DescriptionContainer>
          {`Try searching for what you're looking for or browse some of our favourites below:`}
        </DescriptionContainer>
      </TitleAndDescriptionContainer>
      <SearchContainer>
        <StyledBaseLink as="/search" pathname={pathname} query={query}>
          Search SCMP
        </StyledBaseLink>
      </SearchContainer>
      <FavouriteContainer>
        <FavouriteTitle>Stories you may have missed</FavouriteTitle>
        <FavouriteContentContainer>
          {items?.edges.map((edge, index) => (
            <FavouriteContent key={index} reference={edge.node} />
          ))}
        </FavouriteContentContainer>
      </FavouriteContainer>
    </MainContainer>
  );
};

Main.displayName = "Main";
