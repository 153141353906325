/**
 * @generated SignedSource<<f490ad18efc420b30f8bbd262fee7887>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type favouriteContentContent$data = {
  readonly headline: string | null | undefined;
  readonly images: ReadonlyArray<{
    readonly size_250x250: {
      readonly url: string | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly socialHeadline: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"entityLink">;
  readonly " $fragmentType": "favouriteContentContent";
};
export type favouriteContentContent$key = {
  readonly " $data"?: favouriteContentContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"favouriteContentContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "favouriteContentContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "socialHeadline",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        {
          "alias": "size_250x250",
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "style": "250x250"
              }
            }
          ],
          "concreteType": "ImageStyle",
          "kind": "LinkedField",
          "name": "style",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": "style(filter:{\"style\":\"250x250\"})"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "385cb6040c86b4ce947185f01542743b";

export default node;
