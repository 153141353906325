import styled from "@emotion/styled";

import ScmpLogo from "./scmp-logo.svg";

export const HeaderContainer = styled.header`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;

  block-size: 60px;
  max-inline-size: 1782.86px;
  padding: 0;

  ${props => props.theme.breakpoints.up("tablet")} {
    padding-block: 0;
    padding-inline: 24px;
  }
`;

export const StyledScmpLogo = styled(ScmpLogo)`
  inline-size: 241px;
  block-size: 25px;
  margin-block-start: 0;
  margin-inline-start: 16px;
`;
