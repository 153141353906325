import { graphql } from "react-relay";

// Preventing 404 is not usable for the query name
// I.e: Queries in graphql tags must start with the module name ('404') and end with 'Query'
export const notFoundQuery = graphql`
  query notFoundPageQuery {
    queue(filter: { name: "homepage_top_int" }) {
      ...mainFavouriteQueue
    }
  }
`;
