import type { FunctionComponent } from "react";

import { tracking } from "shared/data";

import { BaseLink } from "scmp-app/components/common/base-link";
import { page as HomePage } from "scmp-app/pages/home";

import { HeaderContainer, StyledScmpLogo } from "./styles";

export const Header: FunctionComponent = () => (
  <HeaderContainer>
    <BaseLink
      as="/"
      {...HomePage.route({
        module: tracking.module.SCMPFavouritesLink,
        pgtype: tracking.pageType.NotFound,
      })}
    >
      <StyledScmpLogo height={50} width={250} />
    </BaseLink>
  </HeaderContainer>
);

Header.displayName = "Header";
