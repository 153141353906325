import styled from "@emotion/styled";

export const PageContainer = styled.div`
  inline-size: 100%;
  block-size: 100%;

  overflow: scroll;
  /* stylelint-disable-next-line csstree/validator */
  overflow: hidden overlay;
  scrollbar-gutter: stable;
`;
