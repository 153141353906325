import styled from "@emotion/styled";
import { fontMerriweather, fontRoboto } from "@product/scmp-sdk";

import { BaseLink } from "scmp-app/components/common/base-link";

export const MainContainer = styled.main`
  inline-size: 100%;
  max-inline-size: 1024px;
  margin-block: 2px 90px;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block: 100px 90px;
    margin-inline: auto;
  }
`;

export const TitleAndDescriptionContainer = styled.div`
  padding-block: 0;
  padding-inline: 20px;

  ${props => props.theme.breakpoints.up("tablet")} {
    padding-block: 0;
    padding-inline: 40px;
  }
`;

export const TitleContainer = styled.div`
  margin-block-end: 12px;

  color: #001246;
  font-weight: 900;
  font-size: 100px;
  font-family: ${fontRoboto};
  line-height: 117px;
`;

export const DescriptionContainer = styled.div`
  margin-block-end: 12px;

  color: #000000;
  font-weight: bold;
  font-size: 20px;
  font-family: ${fontMerriweather};
  line-height: 26px;

  ${props => props.theme.breakpoints.up("tablet")} {
    font-weight: bold;
    font-size: 24px;
    font-family: ${fontMerriweather};
    line-height: 35px;
  }
`;

export const FavouriteContainer = styled.nav`
  margin-block: 48px 30px;
  padding-block: 0;
  padding-inline: 20px;

  ${props => props.theme.breakpoints.up("tablet")} {
    padding-block: 0;
    padding-inline: 40px;
  }
`;

export const FavouriteTitle = styled.div`
  position: relative;

  margin-block-end: 40px;
  padding-block-start: 10px;

  color: #001246;
  font-weight: bold;
  font-size: 20px;
  font-family: ${fontRoboto};
  line-height: 28px;

  &:before {
    position: absolute;
    inset-block-start: 0;
    content: "";

    inline-size: 28px;
    block-size: 3px;

    border-block-start: 3px solid #ffca05;
  }

  ${props => props.theme.breakpoints.up("tablet")} {
    font-weight: bold;
    font-size: 24px;
    font-family: ${fontRoboto};
  }
`;

export const FavouriteContentContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: baseline;
  ${props => props.theme.breakpoints.up("tablet")} {
    flex-flow: row wrap;
  }
`;

export const StyledBaseLink = styled(BaseLink)`
  display: flex;

  inline-size: 100%;
  padding-block-end: 12px;

  color: #999999;
  font-size: 24px;
  font-family: ${fontRoboto};
  line-height: 32px;

  border-block-end: 1px solid #000000;

  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 48px;
    font-family: ${fontRoboto};
    line-height: 57px;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-start;

  margin-block-start: 30px;
  padding-block: 0;
  padding-inline: 20px;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-start: 48px;
    padding-block: 0;
    padding-inline: 40px;
  }
`;
