import { findFirstNonEmptyString } from "@product/scmp-sdk";
import first from "lodash/first";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { tracking } from "shared/data";

import { ArticleImage } from "scmp-app/components/article/article-image";
import { EntityLink } from "scmp-app/components/entity-link";
import type { favouriteContentContent$key } from "scmp-app/queries/__generated__/favouriteContentContent.graphql";

import { FavouriteContentContainer } from "./styles";

export type Props = {
  reference: favouriteContentContent$key;
};

export const FavouriteContent: FunctionComponent<Props> = ({ reference: reference_ }) => {
  const content = useFragment(
    graphql`
      fragment favouriteContentContent on Content {
        headline
        socialHeadline
        ...entityLink
        images {
          size_250x250: style(filter: { style: "250x250" }) {
            url
          }
        }
      }
    `,
    reference_,
  );
  const { headline, images, socialHeadline } = content;

  return (
    <FavouriteContentContainer>
      <EntityLink
        query={{
          module: tracking.module.SCMPFavouritesLink,
          pgtype: tracking.pageType.NotFound,
        }}
        reference={content}
      >
        <ArticleImage
          imageProps={{
            $aspectRatio: "250 / 250",
            $objectFit: "cover",
            lazyload: false,
            src: first(images)?.size_250x250?.url ?? "",
          }}
          title={findFirstNonEmptyString(socialHeadline, headline)}
        />
      </EntityLink>
    </FavouriteContentContainer>
  );
};

FavouriteContent.displayName = "FavouriteContent";
